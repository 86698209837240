/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// ion-menu{
//     // width: 10% !important;
// --ion-background-color:black;
// --ion-text-color:#fff; 
// ion-menu.ion-icon{
//     --ion-text-color:#fff; 
// }
// }

ion-split-pane.split-pane-md.split-pane-visible
ion-menu.split-pane-side {
    min-width: 80px;
    max-width: 80px;
    height: 100vh;
}

.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
    white-space: normal;
}
.popup{
    --background: transparent;
    --box-shadow: none;
    --backdrop-opacity: 0.7;
}

.successpopup{
    --background: transparent;
    --box-shadow: none;
    --backdrop-opacity: 0.7;
}



   ion-searchbar{        
    .searchbar-input-container{            
        .searchbar-input{
            border: 1px solid #8e8e8e;
            border-radius: 5px;
        }
    }
 }
 .searchbar-search-icon.sc-ion-searchbar-md, .searchbar-cancel-button.sc-ion-searchbar-md {
    position: absolute;
    color: #00333B;
}
ion-segment-button {
    --background-checked: #00333B;
    // --background-checked: blue;
    --color: #00333B;
    --color-checked:white;
  }
  .ngx-pagination .current {
    background: #00333B !important;
    border-radius: 20px !important; 
    border: transparent !important;
  }
  .form11component{
      --width:70%;
      
  }
  :root {
    .popover-content {
        width: 20%;
        content: auto;
    }

    .alertCancel{
        --background: transparent;
        --box-shadow: none;
        --backdrop-opacity: 0.7;
        --background: url("/assets/image/SuccessAlert2.png") no-repeat center center / cover;

    }
  }
  ion-select::part(icon) { 
	color: #00333b;
	opacity: 1;
}

.tooltipPopup{
  .popover-content {
    width: 50%;
    content: auto;
}
}


// .mat-select-panel{
//     position: absolute;
//     top: 50px;
//     left: 6%;
//     min-width: calc(100% + 15px) !important;
//     border-radius: 0px !important;
//     // border: 1px solid blue;
//     border-top-style: none;
//   }



  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


// .my-custom-class{
//     --background: transparent;
// }


.mat-select-panel{
  position: absolute;
  top: 45px;
  left: 3%;
  min-width: calc(100% + 15px) !important;
  border-radius: 0px !important;
  // border: 1px solid blue;
  border-top-style: none;
}



.modelpage .modal-wrapper {    
  --height: 75%;
  position: absolute; 
  top: 10%;
  --width: 50%;
  // border-radius: 25px;
  display: block;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}